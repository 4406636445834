<template>
  <div class="notification-block">
    <div class="notification-content">
      <div class="notification-header">Account activation</div>
      <div class="notification-body">
        We have sent an email to <strong id="activation-email">{{ email }}</strong>,
        please <strong>check and activate your account</strong>. If you haven't received it
        in 1-5 minutes, check your spam or trash folder as well.
      </div>
      <div class="notification-footer">
        <div class="btn btn-default"
             @click="resendEmail">Resend
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {httpRequest} from "@/api";

export default {
  name: "AccountNotification",
  props: {
    email: {
      type: String
    }
  },
  methods: {
    async resendEmail() {
      const url = process.env.VUE_APP_API_URL + 'auth/verification-notification';
      const formdata = new FormData();
      formdata.append("email", this.email);
      formdata.append("from_url", window.location.origin + this.$route?.path);
      const result = await httpRequest('POST', url, formdata);
    }
  }
}
</script>

<style scoped lang="scss">
.notification-block .notification-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #102148;
  margin-bottom: 12px;
}

.notification-footer {
  margin-top: 16px;
}

.btn-default {
  max-width: 180px;
  height: 48px;
}
</style>