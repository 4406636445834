<template>
  <main class="full-width auth-page">
    <the-new-header :show-user-details="false" />
    <div class="authorization-page">
      <div class="auth-content-block"
           :class="[{login: currentComponent === 'LoginForm'}, {'reset-password-form': currentComponent === 'ResetPasswordForm'}]">
        <div class="form-wrap">
          <div class="tabs-links footer-tab-links"
               v-if="currentComponent !== 'ResetPasswordForm' && currentComponent !== 'AccountNotification'">
            <div class="tab-link-wrap">
              <button type="button"
                      class="tab-link btn"
                      :class="[{active: currentComponent === 'LoginForm'}]"
                      @click="tabClickHandler({componentName: 'LoginForm'})">Login
              </button>
            </div>
            <div class="tab-link-wrap">
              <button type="button"
                      class="tab-link btn"
                      :class="[{active: currentComponent === 'RegisterForm'}]"
                      @click="tabClickHandler({componentName: 'RegisterForm'})">Sign up
              </button>
            </div>
          </div>

          <keep-alive>
            <component :is="currentComponent"
                       @form-clicked="tabClickHandler($event)"
                       :email="registrationEmail"></component>
          </keep-alive>
        </div>

        <div class="auth-page-image">
          <img src="@/assets/images/login-illustration.svg"
               width="552"
               alt="Login illustration">
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LoginForm from '@/components/Forms/LoginForm/index.vue';
import AccountNotification from '@/components/UI/AccountNotification/index.vue';
import RegisterForm from '@/components/Forms/RegisterForm/index.vue';
import ResetPasswordForm from '@/components/Forms/ResetPasswordForm/index.vue';
import TheNewHeader from "@/components/TheNewHeader/index.vue";

export default {
  name: "Authorization",
  components: {
    TheNewHeader,
    LoginForm,
    RegisterForm,
    AccountNotification,
    ResetPasswordForm
  },
  data() {
    return {
      currentComponent: 'LoginForm',
      registrationEmail: '',
      name: '',
    }
  },
  beforeCreate() {
    // if (getCookie('token')) {
    //   this.$router.replace('/');
    // }
  },
  methods: {
    tabClickHandler(data) {
      this.currentComponent = data.componentName;
      if (data.email) {
        this.registrationEmail = data.email;
      }
    },
  }

}
</script>

<style lang="scss" src="./styles.scss"></style>