<template>
  <div class="auth-form">
    <form @submit.prevent="formSubmit">
      <fieldset>
        <div class="form-row">
          <div class="form-col w50p">
            <div class="form-label">Name</div>
            <input-block type="name"
                         name="name"
                         :min="1"
                         :max="30"
                         bg-is-transparent
                         :input-value="userFormData.name"
                         @inputChanged="inputChanged($event, 'userFormData', 'name')"
                         :error-message="errors?.name"
                         placeholder="Augusta"
                         required></input-block>
          </div>

          <div class="form-col w50p">
            <div class="form-label">Last Name</div>
            <input-block type="surname"
                         name="surname"
                         :min="1"
                         :max="30"
                         bg-is-transparent
                         :input-value="userFormData.surname"
                         @inputChanged="inputChanged($event, 'userFormData', 'surname')"
                         placeholder="Foster"
                         :error-message="errors?.surname"></input-block>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <div class="form-label">Email</div>
            <input-block type="email"
                         name="email"
                         :max="50"
                         bg-is-transparent
                         :input-value="userFormData.email"
                         @inputChanged="inputChanged($event, 'userFormData', 'email')"
                         :error-message="errors?.email"
                         placeholder="marketing@tesla.com"
                         required></input-block>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col w50p">
            <div class="form-label">Password</div>
            <input-block type="password"
                         name="password"
                         :min="8"
                         :max="30"
                         bg-is-transparent
                         :input-value="userFormData.password"
                         @inputChanged="inputChanged($event, 'userFormData', 'password')"
                         :error-message="errors?.password ?? null"
                         password-trigger
                         placeholder="8+, 1 number + capital letter"
                         required></input-block>
          </div>
          <div class="form-col w50p">
            <div class="form-label">Repeat password</div>
            <input-block type="password"
                         name="password_confirmation"
                         :min="8"
                         :max="30"
                         bg-is-transparent
                         :input-value="userFormData.password_confirmation"
                         @inputChanged="inputChanged($event, 'userFormData', 'password_confirmation')"
                         :error-message="errors?.password_confirmation ?? null"
                         password-trigger
                         placeholder="Repeat your password"
                         required></input-block>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">Choose your position</div>
          <div class="form-control">
            <custom-select :options="options"
                           :error-message="errors?.position_id ?? null"
                           bg-is-transparent
                           direction="up"
                           @selectClicked="selectClicked"/>
          </div>
        </div>
        <div class="form-row mar-none">
          <div class="response-msg-block-wrap"></div>
        </div>
        <div class="form-row btn-row">
          <button type="submit"
                  class="btn btn-default btn-rounded sign-in-btn">Sign up
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import {deleteCookie, setCookie} from "@/utils/utils";
import {httpRequest} from "@/api";
import CustomSelect from "@/components/Forms/CustomSelect/index";
import InputBlock from "@/components/Forms/InputBlock/index";

export default {
  name: "RegisterForm",
  data() {
    return {
      userFormData: {
        name: '',
        surname: '',
        email: '',
        password: '',
        password_confirmation: '',
        position_id: ''
      },
      options: {},
      errors: {},
      env: process.env.VUE_APP_URL,
    }
  },
  emits: ['form-clicked'],
  components: {
    'input-block': InputBlock,
    'custom-select': CustomSelect,
  },
  methods: {
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    async formSubmit() {
      const formdata = new FormData();
      formdata.append("name", this.userFormData.name);
      formdata.append("email", this.userFormData?.email?.toLowerCase());
      formdata.append("password", this.userFormData.password);
      formdata.append("password_confirmation", this.userFormData.password_confirmation);
      formdata.append("position_id", this.userFormData.position_id);
      formdata.append("type", 'tokenized');
      formdata.append("from_url", window.location.origin + this.$route?.path);
      // formdata.append("redirect_to", this.env);

      if (this.userFormData.surname !== '') {
        formdata.append("surname", this.userFormData.surname);
      }
      // this.$emit('form-clicked', {componentName: 'AccountNotification', email: this.userFormData?.email});
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.REGISTER, formdata);

      if (!result?.errors) {
        const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
        setCookie('token', result.token, cookieDomain);
        this.$store.dispatch('SET_TOKEN', result.token);
        deleteCookie('currentAppId');
        this.$emit('form-clicked', {componentName: 'AccountNotification', email: this.userFormData?.email});
      } else {
        this.errors = {...result.errors};
      }
    },
    async getUserPositions() {
      const positions = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.POSITIONS);
      const opts = {};

      if ((positions.errors && false) && (positions.message && false)) {
        return;
      }

      if (!positions?.errors && !positions?.message) {
        positions?.forEach(position => {
          opts[position.id] = position.name;
        });
        this.options = {...opts};
      }
    },
    selectClicked(e) {
      this.userFormData.position_id = e.id;
    }
  },
  async mounted() {
    await this.getUserPositions();
  },
}
</script>