<template>
  <div class="auth-form">
    <div class="reset-password-title">
      Reset Password
    </div>

    <template v-if="!passwordResetSuccess">
      <div class="reset-subtitle">We’ll email you instructions on how to reset your password.</div>

      <form @submit.prevent="formSubmit">
        <fieldset>
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">Email</div>
              <input-block type="email"
                           name="email"
                           :max="50"
                           bg-is-transparent
                           :input-value="userFormData.email"
                           @inputChanged="inputChanged($event, 'userFormData', 'email')"
                           :error-message="errors?.email"
                           placeholder="Enter your email"
                           required></input-block>
            </div>
          </div>

          <div class="form-row mar-none">
            <div class="form-col">
              <div class="response-msg-block-wrap"></div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <button type="submit"
                      class="btn btn-default btn-rounded sign-in-btn">Reset password
              </button>
            </div>
          </div>

          <div class="form-row notification">
            <div class="form-col">
              <div class="back-to-login"
                   @click="loginClickHandler">Back to Login</div>
            </div>
          </div>

        </fieldset>
      </form>
    </template>

    <template v-else>
      <div class="password-reset-success-msg">
        <div class="reset-success-msg">Instruction have been sent to <strong>{{userFormData?.email}}</strong>. Please check your email.</div>
      </div>
    </template>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import InputBlock from "@/components/Forms/InputBlock/index";

export default {
  name: "ResetPasswordForm",
  data() {
    return {
      userFormData: {
        email: '',
      },
      errors: {},
      passwordResetSuccess: false
    }
  },
  components: {
    'input-block': InputBlock,
  },
  methods: {
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    async formSubmit() {
      const formdata = new FormData();
      formdata.append("email", this.userFormData?.email?.toLowerCase());
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("link_to", window.location.protocol + '//' + window.location.hostname + '/reset-password');
      // formdata.append("type", 'tokenized');
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + 'auth/forgot-password', formdata);

      if (result.status && result.status === 'passwords.sent') {
        this.passwordResetSuccess = true;
      } else if (result.status && result.status === 'passwords.user') {
        this.errors = {
          email: 'Unfortunately, we were unable to find this email.'
        };
      } else if (result.status && result.status === 'passwords.throttled') {
        this.errors = {
          email: 'Please wait before retrying.'
        };
      } else if (result.status && result.status === 'passwords.token') {
        this.errors = {
          email: 'This password reset token is invalid.'
        };
      }
    },
    loginClickHandler() {
      this.$emit('form-clicked', {componentName:'LoginForm'});
    }
  },
}
</script>

<style lang="scss" scoped>
.reset-password-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: var(--title-color);
  margin-bottom: 12px;
}

.reset-subtitle {
  font-size: 16px;
  line-height: 1.5;
  color: var(--neutral-800);
  margin-bottom: 24px;
}

.reset-success-msg {
  font-size: 16px;
  line-height: 1.5;
  color: var(--neutral-800);
  text-align: left;

  strong {
    font-weight: 600;
    color: var(--title-color);
  }
}

.back-to-login {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  color: var(--primary-default);
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>