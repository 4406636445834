<template>
  <div class="auth-form">

    <form @submit.prevent="formSubmit">
      <fieldset>
        <div class="form-row">
          <div class="form-col">
            <div class="form-label">Email:</div>
            <input-block type="email"
                         name="email"
                         :max="50"
                         bg-is-transparent
                         :input-value="userFormData.email"
                         @inputChanged="inputChanged($event, 'userFormData', 'email')"
                         :error-message="errors?.email"
                         placeholder="Enter your email"
                         required></input-block>

            <div class="response-message error"
                 v-if="showConfirmEmail">
              Please, confirm your email.
              <button class="resend-btn"
                      type="button"
                      @click="resendEmail">
                Resend email
              </button>
            </div>

            <div class="response-message"
                 :class="[{success: confirmEmailData.resultSuccess}, {error: !confirmEmailData.resultSuccess}]"
                 v-if="showConfirmSuccess">{{ confirmEmailData.success }}
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col">
            <div class="form-label">Password:</div>
            <input-block type="password"
                         name="password"
                         :min="8"
                         :max="30"
                         bg-is-transparent
                         :input-value="userFormData.password"
                         @inputChanged="inputChanged($event, 'userFormData', 'password')"
                         :error-message="errors?.password ?? null"
                         password-trigger
                         placeholder="Enter your password"
                         required></input-block>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col w50p">
            <div class="checkboxes-block">
              <input type="checkbox"
                     name="remember_me"
                     id="remember_me"
                     v-model="userFormData.rememberMe"
                     value="1">
              <label for="remember_me">Don't remember me</label>
            </div>
          </div>
          <div class="form-col w50p">
            <div class="tab-link link-underlined"
                 @click="forgotPasswordHandler">Forgot Password?
            </div>
          </div>
        </div>

        <div class="form-row mar-none">
          <div class="form-col">
            <div class="response-msg-block-wrap"></div>
          </div>
        </div>

        <div class="form-row btn-row">
          <div class="form-col">
            <button type="submit"
                    class="btn btn-default btn-rounded sign-in-btn">Sign in
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import {setCookie} from "@/utils/utils";
import {httpRequest} from "@/api";
import InputBlock from "@/components/Forms/InputBlock/index";

export default {
  name: "LoginForm",
  data() {
    return {
      userFormData: {
        email: '',
        password: '',
        rememberMe: false
      },
      errors: {},
      showConfirmEmail: false,
      showConfirmSuccess: false,
      confirmEmailData: {}
    }
  },
  emits: ['form-clicked'],
  components: {
    'input-block': InputBlock,
  },
  methods: {
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    async formSubmit() {
      const formdata = new FormData();
      formdata.append("email", this.userFormData?.email?.toLowerCase());
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("password", this.userFormData.password);

      this.showConfirmEmail = false;
      this.showConfirmSuccess = false;

      if (!this.userFormData.rememberMe) {
        formdata.append("remember_me", 1);
      }

      formdata.append("type", 'tokenized');
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.LOGIN, formdata);

      if (result.token && !result?.errors) {
        const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
        const days = this.userFormData.rememberMe ? 1 : 365;
        setCookie('token', result.token, cookieDomain, days);

        this.$store.dispatch('SET_TOKEN', result.token);
        window.location.href = '/';
        // this.$router.push('/');
      } else {
        if (result?.errors?.email && result.errors.email[0]?.includes('confirm your email')) {
          this.showConfirmEmail = true;
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(result?.errors?.email[0], 'text/xml');
          this.confirmEmailData.url = htmlDoc.querySelector('.resend-btn').getAttribute('data-url');
          this.confirmEmailData.email = htmlDoc.querySelector('.resend-btn').getAttribute('data-email');
        } else {
          this.errors = {...result.errors};
        }
      }
    },
    forgotPasswordHandler() {
      this.$emit('form-clicked', {componentName: 'ResetPasswordForm'});
    },
    async resendEmail() {
      const formdata = new FormData();
      formdata.append("email", this.confirmEmailData?.email?.toLowerCase());
      formdata.append("from_url", window.location.origin + this.$route?.path);
      const result = await httpRequest('POST', this.confirmEmailData.url, formdata);
      if (result?.status > 300) {
        this.errors = {...result.errors};
      } else {
        this.confirmEmailData.success = result?.message ?? '';
        this.confirmEmailData.resultSuccess = result.message.includes('Verification link sent');
        this.showConfirmSuccess = true;
        this.showConfirmEmail = false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.resend-btn {
  border: 0;
  background: transparent;
  color: inherit;
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
  font-weight: inherit;
}

.sign-in-btn {
  font-weight: bold;
}
</style>